import React, { useEffect, useState, useRef } from "react";
import bgPic from "./assets/bg.png";
import bg_line from "./assets/bg_line.webp";
import product from "./assets/product.webp";
import logo from "./assets/logo.png";
import logo2 from "./assets/logo2.png";
import opacity from "./assets/opacity.png";
import apple from "./assets/apple.png";
import gp from "./assets/gp.png";
import { fetchAndActivate, getValue } from "firebase/remote-config";
import { remoteConfig } from "./index";
import Text from "./components/Text";
import layout1 from "./assets/layout.webp";
import layoutlogin from "./assets/layoutlogin.webp";
import layoutedit from "./assets/layoutedit.webp";
import layouttimeline from "./assets/layouttimeline.webp";
import layoutosc from "./assets/layoutosc.webp";
import layoutnetwork from "./assets/layoutnetwork.webp";
import layoutgroup from "./assets/layoutgroup.webp";
import layoutcue from "./assets/layoutcue.webp";
import layoutsettings from "./assets/layoutsettings.webp";
import layoutstop from "./assets/layoutstop.webp";
import ob1 from "./assets/ob1.png";
import ob2 from "./assets/ob2.png";
import ob3 from "./assets/ob3.png";
import martonszabolcs from "./assets/martonszabolcs.webp";

function App() {
  const [desktopURL, setDesktopURL] = useState("");
  const [androidURL, setAndroidURL] = useState("");
  const [beatatest, setBeatatest] = useState("");
  const [iosURL, setIosURL] = useState("");

  const data = [
    {
      title: "1. Practice",
      info: "The BPM and track duration are set by te Maestro (host phone). The rest of the team will be synced automatically.",
      icon: ob1,
      button: "Next",
    },
    {
      title: "2. Production",
      info: "Everything is arranged by the hand of the Maestro (Desktop). You can schedule messages down to the beat",
      icon: ob2,
      button: "Next",
    },
    {
      title: "Getting Started",
      info: "Join a room or create your own and get this party started!",
      icon: ob3,
      button: "Get started",
    },
  ];

  useEffect(() => {
    let _desktopURL = "";
    let _androidURL = "";
    let _iosURL = "";
    let _beatatest = "";
    fetchAndActivate(remoteConfig)
      .then(() => {
        _desktopURL = getValue(remoteConfig, "desktopURL");
        _androidURL = getValue(remoteConfig, "androidURL");
        _iosURL = getValue(remoteConfig, "iosURL");
        _beatatest = getValue(remoteConfig, "beatatest");
        setDesktopURL(_desktopURL._value);
        setAndroidURL(_androidURL._value);
        setIosURL(_iosURL._value);
        setBeatatest(_beatatest._value);
      })
      .catch((err) => {
        console.log("Failed to fetch remote config", err);
      });
  }, []);

  const applogin = useRef(null);
  const appbpm = useRef(null);
  const desktoplogin = useRef(null);
  const desktoplayout = useRef(null);
  const desktopworkflow = useRef(null);
  const desktoposc = useRef(null);

  return (
    <div className="bg-dark">
      <header className="w-screen h-screen flex flex-col justify-center items-center">
        <img
          className="w-screen h-screen opacity-30 z-10 absolute object-cover"
          src={bgPic}
          alt="background"
        />
        <img
          className="w-screen h-screen opacity-40 z-10 absolute object-cover"
          src={bg_line}
          alt="background"
        />
        <img
          className="w-screen absolute bottom-0 h-14 z-10"
          src={opacity}
          alt="background"
        />

        <div className="z-20 flex flex-col md:flex-row justify-around w-screen items-center">
          <div className="flex justify-between items-center flex-col">
            <img
              className="z-20 mt-20 sm:mt-4 w-1/6"
              src={logo2}
              alt="background"
            />
            <img
              className="z-20 h-8 md:h-10 my-4"
              src={logo}
              alt="background"
            />
            <h1 className="text-light mt-4 text-center  font-space-grotesk text-xl">
              Seamless band metronome
              <br />
              synchronization app
            </h1>
            <div className="z-20 mt-4 hidden md:flex md:display flex-col">
              <button
                disabled={desktopURL === "" || !desktopURL}
                onClick={() => window.open(desktopURL)}
                className={`bg-blue text-white flex items-center justify-center hover:border-2 font-space-grotesk text-md px-4 py-2 rounded-lg ${
                  (desktopURL === "" || !desktopURL) &&
                  "opacity-20 hover:opacity-20"
                }`}
              >
                <div className="font-space-grotesk rounded-lg mt-16 bg-green text-black text-xs py-2 px-4 absolute text-center">
                  {beatatest}
                </div>
                Download Maestro Desktop MacOS
              </button>
              <div className="flex flex-col justify-center border-t mt-4 items-center">
                <div className="font-space-grotesk text-lightGrey mt-4">
                  Download the app
                </div>
                <div className="flex justify-center items-center">
                  <button
                    disabled={iosURL === "" || !iosURL}
                    onClick={() => window.open(iosURL)}
                    className={`hover:border-70 ${
                      (iosURL === "" || !iosURL) &&
                      "opacity-20 hover:opacity-20"
                    }`}
                  >
                    <img
                      className="z-20 h-12 m-4 mt-2"
                      src={apple}
                      alt="background"
                    />
                  </button>
                  <button
                    disabled={androidURL === "" || !androidURL}
                    onClick={() => window.open(androidURL)}
                    className={`hover:opacity-70 ${
                      (androidURL === "" || !androidURL) &&
                      "opacity-20 hover:opacity-20"
                    }`}
                  >
                    <img
                      className="z-20 h-12 m-4 mt-2"
                      src={gp}
                      alt="background"
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <img
            className="z-10 mt-10 md:mt-20 ml-4 xl:mr-20 w-3/4 md:w-3/6 self-center"
            src={product}
            alt="background"
          />
          <div className="z-20 mt-8 flex md:hidden md:display flex-col">
            <button
              disabled={desktopURL === "" || !desktopURL}
              onClick={() => window.open(desktopURL)}
              className={`bg-blue text-white flex items-center justify-center hover:border-2 font-space-grotesk text-md px-4 py-2 rounded-lg ${
                (desktopURL === "" || !desktopURL) &&
                "opacity-20 hover:opacity-80"
              }`}
            >
              <div className="font-space-grotesk rounded-lg mt-16 bg-green text-black text-xs py-2 px-4 absolute text-center">
                {beatatest}
              </div>
              Download Maestro Desktop MacOS
            </button>
            <div className="flex flex-col justify-center border-t mt-4 items-center">
              <div className="font-space-grotesk text-lightGrey mt-4">
                Download the app
              </div>
              <div className="flex justify-center items-center">
                <button
                  disabled={iosURL === "" || !iosURL}
                  onClick={() => window.open(iosURL)}
                  className={`hover:opacity-70 ${
                    (iosURL === "" || !iosURL) && "opacity-20 hover:opacity-20"
                  }`}
                >
                  <img
                    className="z-20 h-12 m-4 mt-2"
                    src={apple}
                    alt="background"
                  />
                </button>
                <button
                  disabled={androidURL === "" || !androidURL}
                  onClick={() => window.open(androidURL)}
                  className={`hover:opacity-70 ${
                    (androidURL === "" || !androidURL) &&
                    "opacity-20 hover:opacity-20"
                  }`}
                >
                  <img
                    className="z-20 h-12 m-4 mt-2"
                    src={gp}
                    alt="background"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div className="flex flex-col mt-20 items-center justify-center">
        <div className="font-space-grotesk text-light text-lg w-2/3 md:w-2/3 mt-8 ">
          Achieving perfect accuracy with the whole band has never been easier!
          Elevate your band's musical experience with BeatSynQ, the ultimate
          synchronization app designed for seamless collaboration during
          practice sessions and concerts. Whether you're creating a new room or
          joining an existing one, BeatSynQ ensures that every member of your
          band stays perfectly in sync.
        </div>
      </div>
      <div className="flex w-full flex-col mt-32 md:flex-row items-center justify-between">
        <div className="flex flex-col items-center justify-center">
          <div className="flex flex-col bg-red py-8 items-center justify-center">
            <Text type="h5" className="text-white mb-8">
              {data[0].title}
            </Text>
            <img
              src={data[0].icon}
              className="w-48 mb-4"
              alt={Math.random.toString()}
            />
            <div className="text-white text-center w-7/12  md:w-5/12 text-base bg-red font-space-grotesk mt-4 px-0">
              {data[0].info}
            </div>
          </div>
        </div>
        <div className="flex flex-col items-center justify-center">
          <div className="flex flex-col bg-green py-8 items-center justify-center">
            <Text type="h5" className="text-white mb-8">
              {data[1].title}
            </Text>
            <img
              src={data[1].icon}
              className="w-48 mb-4"
              alt={Math.random.toString()}
            />
            <div className="text-white text-center w-7/12  md:w-5/12 text-base font-space-grotesk mt-4 px-0">
              {data[1].info}
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center justify-center">
        <div className="flex flex-col w-full bg-black py-8 items-center justify-center">
          <Text type="h5" className="text-white mb-8">
            {data[2].title}
          </Text>
          <img
            src={data[2].icon}
            className="w-48 mb-4"
            alt={Math.random.toString()}
          />
          <div className="text-white text-center w-7/12  md:w-5/12 text-base  font-space-grotesk mt-4 px-0">
            {data[2].info}
          </div>
        </div>
      </div>
      <div className="border-light border-t py-10 mt-0 bg-black flex flex-col items-center justify-center w-8/9 px-5 rounded p-2">
        <img
          src={martonszabolcs}
          className="w-1/2 md:w-1/4 rounded-xl shadow"
          alt="martonszabolcs"
        />
        <Text type="h6" className="text-white mt-8">
          Marton Szabolcs
        </Text>
        <div
          type="body"
          className="text-white font-space-grotesk mt-4 w-1/2 md:w-1/4"
        >
          Developer, Owner | Musician, Producer, Composer of{" "}
          <a
            className="underline text-light hover:opacity-50"
            href="https://kvarkmusic.com"
          >
            KVARK
          </a>
        </div>
        <div
          onClick={() => window.open("mailto:hello@beatsynq.com")}
          type="body"
          className="text-white font-space-grotesk w-1/2 md:w-1/4 hover:opacity-50 cursor-pointer my-2"
        >
          hello@beatsynq.com
        </div>
        <div
          type="small"
          className="text-light text-sm font-space-grotesk w-1/2 md:w-1/4 mt-2"
        >
          The aspiring artist at the beginning wanted to make his concerts
          easier. Now he is sharing the outcome with the world. BeatSynQ is an
          app from an artist to artists.
        </div>
      </div>
      <div className="w-screen mt-20 flex items-center justify-center">
        <div className="flex-col h-full w-11/12 md:w-2/3 items-center px-2">
          <div className="px-4 max-md:px-5">
            <Text type="h1" className="underline text-white">
              How it works
            </Text>
            <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
              <div className="flex flex-col max-md:w-full max-md:ml-0">
                <div className="flex flex-col">
                  <Text type="h6" className="mt-4 text-white">
                    App
                  </Text>
                  <Text
                    onClick={() =>
                      applogin.current.scrollIntoView({ behavior: "smooth" })
                    }
                    className="cursor-pointer hover:opacity-80"
                  >
                    Login/join screen
                  </Text>

                  <Text
                    onClick={() =>
                      appbpm.current.scrollIntoView({ behavior: "smooth" })
                    }
                    className="cursor-pointer hover:opacity-80"
                  >
                    BPM Slider
                  </Text>
                </div>
              </div>

              <div className="flex flex-col items-stretch w-[56%] ml-5 max-md:w-full max-md:ml-0">
                <div className="flex flex-col items-stretch mt-4">
                  <div className="text-white text-lg font-bold whitespace-nowrap">
                    Maestro Desktop
                  </div>
                  <Text
                    onClick={() =>
                      desktoplogin.current.scrollIntoView({
                        behavior: "smooth",
                      })
                    }
                    className="cursor-pointer hover:opacity-80"
                  >
                    Login/join screen
                  </Text>
                  <Text
                    onClick={() =>
                      desktoplayout.current.scrollIntoView({
                        behavior: "smooth",
                      })
                    }
                    className="cursor-pointer hover:opacity-80"
                  >
                    Layout
                  </Text>
                  <Text
                    onClick={() =>
                      desktopworkflow.current.scrollIntoView({
                        behavior: "smooth",
                      })
                    }
                    className="cursor-pointer hover:opacity-80"
                  >
                    Workflow
                  </Text>
                  <Text
                    onClick={() =>
                      desktoposc.current.scrollIntoView({ behavior: "smooth" })
                    }
                    className="cursor-pointer hover:opacity-80"
                  >
                    OSC Sync
                  </Text>
                </div>
              </div>
            </div>
          </div>

          <div className="border-light w-8/9 py-4 mt-10 px-5 rounded p-2">
            <Text type="h6" className="text-white">
              App
            </Text>
            <div ref={applogin}>
              <Text type="body" className="text-white my-4 font-bold">
                Login/join screen
              </Text>
            </div>
            <div className="text-white font-space-grotesk text-sm">
              Create a new room or join an existing one and get synced up with
              your band!
              <div className="text-white font-space-grotesk text-sm">
                Enter the name and password of the room you’d like to join and
                press ’Connect’. If the given room already exists, you’ll enter
                the room and get synced up with all the other connected devices
                automatically.
                <br />
                Creating a new room is just as easy and simple – enter the
                desired name and password for your room and press ’Connect’.
                <br /> BeatSynQ will create your room with the login parameters
                given by you.
                <br />
                <br /> After logging in a QR code and the room’s login info pops
                up. Use this screen to share the room with your band and get
                everyone together.
              </div>
              <div ref={appbpm}>
                <Text type="body" className="text-white my-4 font-bold">
                  BPM Slider
                </Text>
              </div>
              <div className="text-white font-space-grotesk text-sm">
                Synchronizes the metronome for every connected device.
                <br />
                Adjust the BPM as needed to start the metronome. The app will
                sync it up for every device connected in the room and makes a
                practice session or a concert smooth, easy, and perfectly
                accurate.
              </div>
            </div>
          </div>
          <div className="border-light w-8/9 py-4 mt-4 px-4 rounded p-2">
            <Text type="h6" className="text-white">
              Maestro Desktop
            </Text>
            <div ref={desktoplogin}>
              <Text type="body" className="text-white my-4 font-bold">
                Login/join screen
              </Text>
            </div>
            <img alt="beatsynq-layout" src={layoutlogin} />

            <div className="text-white font-space-grotesk text-sm">
              Create a new room or join an existing one and get synced up with
              your band!
              <br />
              Enter the name and password of the room you’d like to join and
              press ’Connect’. If the given room already exists, you’ll enter
              the room and get synced up with all the other connected devices
              automatically. <br />
              Creating a new room is just as easy and simple – enter the desired
              name and password for your room and press ’Connect’. BeatSynQ will
              create your room with the login parameters given by you.
              <br />
              <br />
              After logging in a QR code and the room’s login info pops up. Use
              this screen to share the room with your band and get everyone
              together.
              <br />
              <br />
              You can choose not to log in to any room. In that case you can
              only edit or delete your tracks, playing them is not possible.
              <div ref={desktoplayout}>
                <Text type="body" className="text-white my-4 font-bold">
                  Layout
                </Text>
              </div>
              <img alt="beatsynq-layout" src={layout1} />
              <div className="text-white font-space-grotesk text-sm">
                Tracktable
                <br />
                <br />
                - Ready: You have successfully uploaded every component needed
                for your track and it’s ready to be played.
                <br />
                <br />
                Connected devices
                <br />
                <br />
                - Shows every device that’s joined in the room.
                <br />
                - The little house icon marks the host device. <br />
                - You can see one by one: <br />
                - if the current track is playing on the device
                <br />- cur: shows the current bar and quarter where the device
                is at <br />
                - batt: shows the battery level of the device
                <br />
                <br />
                Header
                <br />
                <br />
                Here you can see the statuses of the different services: <br />-
                if the OSC server is up, you can see the number of the port{" "}
                <br />
                - whether the device is connected to the internet <br />
                - whether you’re joined in a room <br />
                -&gt; if every service has a green dot next to it, your track is
                ready to be played!
                <br />
                <br />
                If you want to leave the room, switch rooms, or see the login
                parameters of your current room, click on „Connected room:”
                <br />
                <br />
                Controls <br />
                <br />
                - SPACE button: start track <br />
                - ESC button: stop track <br />
                - Up/Down buttons: select a track
                <br />
              </div>
              <div ref={desktopworkflow}>
                <Text type="body" className="text-white my-4 font-bold">
                  Workflow
                </Text>
              </div>
              <div className="text-white font-space-grotesk text-sm">
                After you have successfully joined or created a room let’s add
                your first song! Simply click on the displayed text to do so.
                <br />
                Three tabs appear where you can adjust and set up everything for
                a perfectly synced and accurate performance, fully tailored to
                your music.
                <br />
                <br />
                Edit tab
                <br />
                <img alt="beatsynq-layout" src={layoutedit} />
                <br />
                • ID: the sequence of the tracks (also used in OSC Message)
                <br />
                • Length: the length of the track (the length of time when the
                metronome is playing)
                <br />
                • BPM: the tempo of the metronome
                <br />
                • Keep tempo: if this box is unchecked, you can’t start playing
                your track until a MIDI file is uploaded
                <br />
                • Title: the title of the track – also displayed on connected
                phones
                <br />
                • Key: the key of the track – also displayed on connected phones
                <br />
                • Signature: the signature of the track – also displayed on
                connected phones
                <br />
                • Remove track: delete the current track
                <br />
                <br />
                Timeline tab
                <br />
                <img alt="beatsynq-layout" src={layouttimeline} />
                <br />
                If the „Keep tempo” box in the „Edit” tab is not checked, you
                can upload your MIDI file here.
                <br />
                You can add flags to your track. Flags are messages/lyrics
                reminders/whatever texts that you want to be displayed on
                connected phones when the track gets to a certain bar.
                <br />
                How to add your flags:
                <br />
                • Click on the „New flag” button
                <br />
                • Start: the number of the bar when you want your text displayed
                <br />
                • Text: the text to be displayed at the chosen time
                <br />
                You can delete a flag by clicking on it.
                <br />
              </div>
            </div>
            <div ref={desktoposc}>
              <Text type="body" className="text-white my-4 font-bold">
                OSC Sync Parameters
              </Text>
            </div>
            <img alt="beatsynq-layout" src={layoutosc} />
            <div className="text-white font-space-grotesk text-sm">
              On this tab, you can get all the parameters needed to link
              BeatSynQ to other programs using the OSC Network.
              <br />
              For an easier explanation of how exactly to do that, let us walk
              you through an example.
              <br />
              <br />
              Example: linking BeatSynQ to QLab
              <br />
              <br />
              1. In QLab, follow this route: click on „Open workspace settings”
              -&gt; then „Network” -&gt; then „New network patch” and enter the
              following parameters:
              <br />
              • type: OSC Message
              <br />
              • network: ODP
              <br />
              • destination: the destination info can be found in BeatSynQ (OSC
              Sync Parameters tab, top left corner – localhost; port)
              <br />
              <img alt="beatsynq-layout" src={layoutnetwork} />
              <br />
              2. After setting up these parameters create a group cue in QLab
              <br />
              <img
                alt="beatsynq-layout"
                src={layoutgroup}
                className="mt-4 rounded-xl"
              />
              <br />
              3. Add a network cue to your group cue
              <br />
              <img
                alt="beatsynq-layout"
                src={layoutcue}
                className="mt-4 rounded-xl"
              />
              <br />
              4. Go to the settings tab in the network cue
              <br />
              • patch: the patch that you’ve created at the first step
              <br />
              • message: in BeatSynQ the message is always a slash and the track
              ID (e.g. /3)
              <br />
              • • the message can be found in BeatSynQ (OSC Sync Parameters tab,
              OSC message field)
              <br />
              <img
                alt="beatsynq-layout"
                src={layoutsettings}
                className="mt-4 rounded-xl"
              />
              <br />
              5. Now let’s set up our panic button! In QLab create a new network
              cue, and open its settings tab
              <br />
              • patch: the patch that you’ve created at the first step
              <br />• message: /stop
              <br />
              <img
                alt="beatsynq-layout"
                src={layoutstop}
                className="mt-4 rounded-xl"
              />
              <br />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
