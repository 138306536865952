import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getRemoteConfig } from "firebase/remote-config";

const firebaseConfig = {
  apiKey: "AIzaSyB7h5ROKbv0Jl69L4Sy5aUsXs-UftU22ds",
  authDomain: "beatsynq-e71d9.firebaseapp.com",
  projectId: "beatsynq-e71d9",
  storageBucket: "beatsynq-e71d9.appspot.com",
  messagingSenderId: "857232587231",
  appId: "1:857232587231:web:c4cefb9c269176bab6ec6a",
  measurementId: "G-32MYFJWHMR"
};

export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const remoteConfig = getRemoteConfig(app);
remoteConfig.settings.minimumFetchIntervalMillis = 3000;


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

reportWebVitals();
