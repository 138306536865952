import React from "react";

interface TextProps {
  type?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "body" | "span" | "small";
  children: string;
  style?: React.CSSProperties;
  className?: string;
}

const variant = {
  h1: "text-6xl font-bold font-space-grotesk text-light",
  h2: "text-5xl font-bold font-space-grotesk text-light",
  h3: "text-4xl font-bold font-space-grotesk text-light",
  h4: "text-3xl font-bold font-space-grotesk text-light",
  h5: "text-2xl font-bold font-space-grotesk text-light",
  h6: "text-xl font-bold font-space-grotesk text-light",
  body: "text-base font-space-grotesk text-light",
  span: "text-sm font-space-grotesk text-light",
  small: "text-xs font-space-grotesk text-light",
};

const Text: React.FC<TextProps> = ({
  type = "body",
  children,
  className,
  style,
  ...rest
}) => (
  <div
    className={`${variant[type]} ${className} select-none cursor-default overflow-hidden truncate`}
    style={style}
    {...rest}
  >
    {children}
  </div>
);

export default Text;
